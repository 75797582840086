import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

import './Common.css'

const ConfirmDialog = ({ title, open, setOpen, onConfirm, buttonText, isPositive }) => {

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other} >
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const handleClose = () => {
        setOpen(false);
    };
    if (!open) {
        return ""
    }
    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} style={{ minWidth: '300px' }}>
                    {title}
                </BootstrapDialogTitle>

                <DialogContent dividers className="flexContainerCenter">
                    <Typography variant="subtitle1"><b>Bist du dir Sicher?</b></Typography>

                </DialogContent>
                <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button variant='outlined' onClick={() => handleClose()}>
                        Abbrechen
                    </Button>
                    <Button className={!isPositive ? "errorButton" : "successButton"} onClick={onConfirm}>
                        {buttonText === undefined ? "Ja" : buttonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmDialog;
